export default {
  poloBlue: '#89A4D7',
  cloudBurst: '#192F58',
  spindle: '#B5D7F2',
  white: '#FFFFFF',
  frenchGrey: '#818181',
  morningGlory: '#81DFEB',
  panache: '#EBF2F0',
  paynesGray: '#414447',
  celesta: '#8F545E',
  locust: '#A3A77A',
  cgRed: '#FF1A1D',
  fadedBlue: '#6779FF',
  ultramarineBlue: '#315CF6',
  mauve: '#FF9FFD',
  lust: '#FF0000',
  lusty: '#852320',
  beautyBush: '#F9B2AB',
  sail: '#A5E9FC',
  twilight: '#EDCCDD'
};
